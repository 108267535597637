import { css, SerializedStyles } from '@emotion/react';
import { getGutter, getColumn, getGrid } from './functions';
import { clearfix, desktop, desktopLarge, mobile, tablet } from './mixins';

const wideGutterHalf = getGutter(`wide`, (size) => size / 2);
const desktopLargeGutterHalf = getGutter(`large`, (size) => size / 2);
const desktopGutterHalf = getGutter(`desktop`, (size) => size / 2);
const tabletGutterHalf = getGutter(`tablet`, (size) => size / 2);
const mobileGutterHalf = getGutter(`mobile`, (size) => size / 2);

/**
 * dynamically generate the prefix size of the selector
 * it retrieves the first letter of the given column.
 * @param column
 * @param skipWide determine if we need to skip 'wide' prefixing
 * @returns string e.g. "-w" for wide and if skipWide is false
 */
const generateSizePrefix = (column: string, skipWide = true): string => {
  if (column === `wide` && skipWide) {
    return ``;
  }
  return `-${column.charAt(0)}`;
};

/**
 * This method will generate the repepetive column styles for col-1, col-d-1, etc.
 * @param column
 * @param paddingLeft
 * @param paddingRight
 * @returns
 */
const generateColStyles = (
  column: string,
  paddingLeft: string,
  paddingRight: string,
): SerializedStyles | null => {
  let styles = ``;
  const sizePrefix = generateSizePrefix(column);
  // const sizePrefixOffset = generateSizePrefix(column, false);
  const columns = getColumn(column);
  for (let i = 1; i <= columns; i += 1) {
    const width = (i / columns) * 100;
    styles += `
      .col${sizePrefix}-${i} {
        display: block;
        flex: 0 0 ${width}%;
        padding-left: ${paddingLeft};
        padding-right: ${paddingRight};
        max-width: ${width}%;
      }

      .col-offset${sizePrefix}-${i} {
        position: relative;
        left: ${width / 2}%;
      }

      .col-offset${sizePrefix}-0 {
        position: relative;
        left: 0;
      }
    `;
  }
  return css`
    ${styles}
  `;
};

const Grid = css`
  .container {
    ${clearfix()}
    display: block;
    margin: 0px auto;
    max-width: ${getGrid(`wide`)};
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -${wideGutterHalf};
    margin-right: -${wideGutterHalf};
    clear: both;
  }

  ${generateColStyles(`wide`, wideGutterHalf, wideGutterHalf)}

  ${desktopLarge(css`
    .container {
      max-width: ${getGrid(`large`)};
    }
    .row {
      margin-left: -${desktopLargeGutterHalf};
      margin-right: -${desktopLargeGutterHalf};
    }

    ${generateColStyles(
      `large`,
      desktopLargeGutterHalf,
      desktopLargeGutterHalf,
    )}
  `)}

  ${desktop(css`
    .container {
      max-width: ${getGrid(`desktop`)};
    }
    .row {
      margin-left: -${desktopGutterHalf};
      margin-right: -${desktopGutterHalf};
    }

    ${generateColStyles(`desktop`, desktopGutterHalf, desktopGutterHalf)}
  `)}

  ${tablet(css`
    .container {
      max-width: ${getGrid(`tablet`)};
    }
    .row {
      margin-left: -${tabletGutterHalf};
      margin-right: -${tabletGutterHalf};
    }
    ${generateColStyles(`tablet`, tabletGutterHalf, tabletGutterHalf)}
  `)}

  ${mobile(css`
    .container {
      padding: 0px 24px;
    }
    .row {
      margin-left: -${mobileGutterHalf};
      margin-right: -${mobileGutterHalf};
    }
    ${generateColStyles(`mobile`, mobileGutterHalf, mobileGutterHalf)}
  `)}
`;

export default Grid;
